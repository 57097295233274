import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import Bowser from "bowser";
import "./index.css";
import App from "./App";
import Nope from "./Nope";
import * as serviceWorker from "./serviceWorker";

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const browser = Bowser.getParser(window.navigator.userAgent);

const isValidBrowser = browser.satisfies({
  chrome: ">=60",
  firefox: ">=50",
  safari: ">=11",
  edge: ">=15",
});

const toRender = isValidBrowser ? <App /> : <Nope />;

ReactDOM.render(toRender, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
