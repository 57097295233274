import React from 'react';

const Logo = ({ children }) => (
  <div
    style={{
      color: '#fff',
      fontWeight: 700,
      whiteSpace: 'nowrap',
      marginRight: '20px',
    }}
  >
    {children}
  </div>
);

export default Logo;