import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

import Home from "./pages/Home";
import Search from "./pages/Search";
import DeclarationDetail from "./pages/DeclarationDetail";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Reset from "./pages/Reset";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Declarations from "./pages/Declarations";
import DeclarationForm from "./pages/DeclarationForm";
import DeclarationImport from "./pages/DeclarationImport";
import Beneficiaries from "./pages/Beneficiaries";
import BeneficiaryForm from "./pages/BeneficiaryForm";
import Companies from "./pages/Companies";
import CompanyForm from "./pages/CompanyForm";
import CompanyDetail from "./pages/CompanyDetail";
import CompanyUsers from "./pages/CompanyUsers";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";
import Waiting from "./pages/Waiting";

import "./App.css";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <PublicRoute path="/" exact component={Home} />
          <Route path="/search/:q" component={Search} />
          <Route path="/detail/:id" component={DeclarationDetail} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/reset" component={Reset} />
          <Route path="/logout" component={Logout} />
          <Route path="/waiting" component={Waiting} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/declarations/new" component={DeclarationForm} />
          <PrivateRoute
            path="/declarations/:id/edit"
            component={DeclarationForm}
          />
          <PrivateRoute
            path="/declarations/import"
            component={DeclarationImport}
          />
          <PrivateRoute path="/declarations" component={Declarations} />
          <PrivateRoute path="/beneficiaries/new" component={BeneficiaryForm} />
          <PrivateRoute
            path="/beneficiaries/:id/edit"
            component={BeneficiaryForm}
          />
          <PrivateRoute path="/beneficiaries" component={Beneficiaries} />
          <PrivateRoute path="/companies/new" component={CompanyForm} />
          <PrivateRoute path="/companies/:id/edit" component={CompanyForm} />
          <PrivateRoute path="/companies/:id/view" component={CompanyDetail} />
          <PrivateRoute
            path="/companies/:id/declarations"
            component={Declarations}
          />
          <PrivateRoute path="/companies/:id/users" component={CompanyUsers} />
          <PrivateRoute path="/companies" component={Companies} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
