import 'antd/dist/antd.css';

import React from 'react';
import { Typography } from 'antd';

import Main from '../components/Main';

const { Title } = Typography;

const Home = () => (
  <Main>
    <div style={{ background: '#fff', padding: 24, minHeight: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
      <Typography>
        <Title>404</Title>
      </Typography>
    </div>
  </Main>
);

export default Home;