import "antd/dist/antd.css";

import React from "react";
import { Table, Button, Input, message, Divider, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import Fuse from "fuse.js";

import Main from "../components/Main";

import api from "../helpers/api.js";
import util from "../helpers/util.js";

const { Search } = Input;

class Beneficiaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      filteredData: [],
    };
    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => util.sorterString(a.name, b.name),
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Zip code",
        dataIndex: "zipCode",
        key: "zipCode",
        sorter: (a, b) => util.sorterNumber(a.zipCode, b.zipCode),
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
        sorter: (a, b) => util.sorterString(a.city, b.city),
      },
      {
        title: "Country",
        dataIndex: "country.name",
        key: "country",
        sorter: (a, b) => util.sorterString(a.country.name, b.country.name),
      },
      {
        title: "Unique identifier",
        dataIndex: "uniqueIdentifier",
        key: "uniqueIdentifier",
        sorter: (a, b) =>
          util.sorterString(a.uniqueIdentifier, b.uniqueIdentifier),
      },
      {
        title: "",
        key: "actions",
        render: (text, record) => (
          <span style={{ display: "block", marginBottom: "-10px" }}>
            <Button
              type="primary"
              style={{ marginBottom: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`/beneficiaries/${record.id}/edit`);
              }}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure you want to remove this beneficiary?"
              onConfirm={(e) => {
                e.stopPropagation();
                this.confirm(record.id);
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
              placement="bottomRight"
            >
              <Button
                style={{ marginBottom: "10px" }}
                onClick={(e) => e.stopPropagation()}
              >
                Remove
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.search();
  }

  handleChange = (e) => {
    const query = e.target.value;
    const allBeneficiaries = this.state.allData;
    let filteredBeneficiaries;
    if (query !== "") {
      const fuse = new Fuse(allBeneficiaries, {
        keys: [
          {
            name: "name",
            weight: 0.9,
          },
          {
            name: "country.name",
            weight: 0.8,
          },
          {
            name: "city",
            weight: 0.7,
          },
          {
            name: "zipCode",
            weight: 0.6,
          },
          {
            name: "address",
            weight: 0.3,
          },
        ],
        minMatchCharLength: 1,
        threshold: 0.2,
      });
      filteredBeneficiaries = fuse.search(query).map((result) => result.item);
    } else {
      filteredBeneficiaries = allBeneficiaries;
    }
    this.setState({
      filteredData: filteredBeneficiaries,
    });
  };

  search() {
    api.getMyBeneficiaries((beneficiaries) => {
      if (beneficiaries) {
        this.setState({ allData: beneficiaries, filteredData: beneficiaries });
      } else {
        message.error("An error occurred while retrieving the beneficiaries.");
      }
    });
  }

  remove(id) {
    api.deleteBeneficiary(id, (success, status) => {
      if (success) {
        message.success("The beneficiary was successfully removed!");
        window.location.reload();
      } else {
        if (status === 400) {
          message.error(
            "You are not allowed to remove this beneficiary because it is already linked to at least one declaration."
          );
        } else {
          message.error("An error occurred while removing the beneficiary.");
        }
      }
    });
  }

  confirm(id) {
    this.remove(id);
  }

  render() {
    return (
      <Main>
        <div style={{ marginBottom: "24px" }}>
          <Link to="/beneficiaries/new">
            <Button type="primary">Add a new beneficiary</Button>
          </Link>
        </div>
        <div style={{ marginBottom: "24px", maxWidth: "600px" }}>
          <Search
            placeholder="Filter beneficiaries"
            onChange={this.handleChange}
          />
        </div>
        <Table
          columns={this.columns}
          dataSource={this.state.filteredData}
          rowKey={"id"}
          rowClassName="clickable-row"
          onRow={(record) => {
            return {
              onClick: () => {
                this.props.history.push(`/beneficiaries/${record.id}/edit`);
              },
            };
          }}
        />
      </Main>
    );
  }
}

export default Beneficiaries;
