import React from 'react';
import { Redirect } from "react-router-dom";

import api from '../helpers/api.js';

class Logout extends React.Component {
  componentDidMount() {
    api.signout();
  }

  render() {
    return <Redirect to='/login' />;
  }
}

export default Logout;