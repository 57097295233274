import "antd/dist/antd.css";

import React from "react";
import { Table, Button, Divider, message } from "antd";

import Main from "../components/Main";

import api from "../helpers/api.js";
import util from "../helpers/util.js";

const roles = {
  ROLE_MANAGER: "Manager",
  ROLE_ENCODER: "Encoder",
};

const statuses = {
  active: "Active",
  inactive: "Inactive",
  wait_manager_validation: "Waiting manager validation",
};

class CompanyUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [
      {
        title: "First name",
        dataIndex: "user.firstName",
        key: "firstName",
        sorter: (a, b) => util.sorterString(a.user.firstName, b.user.firstName),
      },
      {
        title: "Last name",
        dataIndex: "user.lastName",
        key: "lastName",
        sorter: (a, b) => util.sorterString(a.user.lastName, b.user.lastName),
      },
      {
        title: "Email",
        dataIndex: "user.email",
        key: "email",
        sorter: (a, b) => util.sorterString(a.user.email, b.user.email),
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (text, record) => <span>{roles[text]}</span>,
        sorter: (a, b) => util.sorterString(a.role, b.role),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => <span>{statuses[text]}</span>,
        sorter: (a, b) => util.sorterString(a.status, b.status),
      },
      {
        title: "",
        key: "actions",
        render: (text, record) => {
          return (
            <span style={{ display: "block", marginBottom: "-10px" }}>
              {this.user["@id"] !== record.user["@id"] && (
                <>
                  {record.status === "active" ? (
                    <Button
                      type="primary"
                      style={{ marginBottom: "10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.deactivate(record.id);
                      }}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      style={{ marginBottom: "10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.activate(record.id);
                      }}
                    >
                      Activate
                    </Button>
                  )}
                  <Divider type="vertical" />
                  {record.role === "ROLE_MANAGER" ? (
                    <Button
                      style={{ marginBottom: "10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.makeEncoder(record.id);
                      }}
                    >
                      Make encoder
                    </Button>
                  ) : (
                    <Button
                      style={{ marginBottom: "10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.makeManager(record.id);
                      }}
                    >
                      Make manager
                    </Button>
                  )}
                </>
              )}
            </span>
          );
        },
      },
    ];
  }

  componentWillMount() {
    api.isCompanyManager(this.props.match.params.id, (result) => {
      if (!result) {
        this.props.history.push(`/dashboard`);
      }
    });
  }

  componentDidMount() {
    api.getCurrentUser((user) => {
      this.user = user;
      api.getCompanyUsers(this.props.match.params.id, (users) => {
        if (users) {
          this.setState({ data: users });
        } else {
          message.error("An error occurred while retrieving the users.");
        }
      });
    });
  }

  activate(id) {
    api.updateUserCompany(
      id,
      {
        status: "active",
      },
      (result) => {
        if (result) {
          this.props.history.go(0);
        } else {
          message.error("An error occurred while updating the user.");
        }
      }
    );
  }

  deactivate(id) {
    api.updateUserCompany(
      id,
      {
        status: "inactive",
      },
      (result) => {
        if (result) {
          this.props.history.go(0);
        } else {
          message.error("An error occurred while updating the user.");
        }
      }
    );
  }

  makeManager(id) {
    api.updateUserCompany(
      id,
      {
        role: "ROLE_MANAGER",
      },
      (result) => {
        if (result) {
          this.props.history.go(0);
        } else {
          message.error("An error occurred while updating the user.");
        }
      }
    );
  }

  makeEncoder(id) {
    api.updateUserCompany(
      id,
      {
        role: "ROLE_ENCODER",
      },
      (result) => {
        if (result) {
          this.props.history.go(0);
        } else {
          message.error("An error occurred while updating the user.");
        }
      }
    );
  }

  render() {
    return (
      <Main>
        <div
          style={{
            fontWeight: "700",
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.85)",
            marginBottom: "20px",
          }}
        >
          Manage company users
        </div>
        <Table
          columns={this.columns}
          dataSource={this.state.data}
          rowKey={"id"}
        />
      </Main>
    );
  }
}

export default CompanyUsers;
