import "antd/dist/antd.css";

import React from "react";
import { Typography } from "antd";

import Main from "../components/Main";

import api from "../helpers/api.js";

const { Title, Paragraph } = Typography;

class Waiting extends React.Component {
  componentDidMount() {
    this.checkUser();
  }

  checkUser() {
    window.setTimeout(() => {
      api.getCurrentUser((user) => {
        if (user.status === "active") {
          localStorage.setItem("status", "active");
          window.location.href = "/";
        } else {
          this.checkUser();
        }
      });
    }, 5000);
  }

  render() {
    return (
      <Main>
        <div
          style={{
            background: "#fff",
            padding: 24,
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography>
            <Title>Please hold on...</Title>
            <Paragraph>
              Your account was successfully created, but it still needs to be
              validated by the system administrator.
              <br />
              You will be able to access the system once your account is
              validated.
              <br />
              If you need more information, please contact{" "}
              <a href="mailto:transparency@medtecheurope.org">
                transparency@medtecheurope.org
              </a>
              .
            </Paragraph>
          </Typography>
        </div>
      </Main>
    );
  }
}

export default Waiting;
