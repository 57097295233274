import 'antd/dist/antd.css';

import React from 'react';
import { Input, Button, message } from 'antd';

import Main from '../components/Main';

import api from '../helpers/api.js';

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.forgot = this.forgot.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  forgot() {
    api.forgot({
      email: this.state.email,
    }, (success) => {
      if (success) {
        message.success('Thank you! You’ll receive an email shortly.');
        this.props.history.push(`/login`);
      } else {
        message.error('It was not possible to send a request to reset your password! Please make sure your email is associated to an existing account.');
      }
    });
  }

  render() {
    return (<Main>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        <div style={{ width: '400px', marginBottom: '20px' }}>
          <div style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.85)', textAlign: 'left' }}>Enter your email to reset your password</div>
        </div>
        <div style={{ width: '400px', marginBottom: '20px' }}>
          <Input name="email" value={this.state.email} onChange={this.handleChange} size="large" placeholder="" addonBefore="Email" autoFocus />
        </div>
        <div style={{ width: '400px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={this.forgot}>Submit</Button>
        </div>
      </div>
    </Main>);
  }
}

export default Forgot;