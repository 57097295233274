import 'antd/dist/antd.css';

import React from 'react';
import { Input, Button, message } from 'antd';
import queryString from 'query-string'

import Main from '../components/Main';

import api from '../helpers/api.js';

class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      token: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (!params.token) {
      this.props.history.push(`/login`);
    } else {
      this.setState({
        token: params.token,
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  reset() {
    api.reset({
      password: this.state.password,
      token: this.state.token,
    }, (success) => {
      if (success) {
        message.success('Thank you! You can now login with your new password.');
        this.props.history.push(`/login`);
      } else {
        message.error('It was not possible to send a request to reset your password!');
      }
    });
  }

  render() {
    return (<Main>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        <div style={{ width: '400px', marginBottom: '20px' }}>
          <div style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.85)', textAlign: 'left' }}>Choose a new password</div>
        </div>
        <div style={{ width: '400px', marginBottom: '20px' }}>
          <Input.Password name="password" value={this.state.password} onChange={this.handleChange} size="large" placeholder="" addonBefore="Password" autoFocus />
        </div>
        <div style={{ width: '400px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={this.reset}>Submit</Button>
        </div>
      </div>
    </Main>);
  }
}

export default Reset;