import React from 'react';
import { Route, Redirect } from "react-router-dom";

import api from '../helpers/api.js';

const PublicRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={(props) => (
		api.isAuthenticated() === false
			? <Component {...props} />
			: <Redirect to='/dashboard' />
	)} />
)

export default PublicRoute;