import React from 'react';

const SearchWrapper = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      maxWidth: '500px',
      marginRight: '20px',
    }}
  >
    {children}
  </div>
);

export default SearchWrapper;