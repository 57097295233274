import "antd/dist/antd.css";

import React from "react";
import { List, Icon } from "antd";
import { Link } from "react-router-dom";

import Main from "../components/Main";

const data = [
  {
    title: "Add a new declaration",
    icon: "plus",
    link: "/declarations/new",
  },
  {
    title: "Import multiple declarations via a CSV file",
    icon: "import",
    link: "/declarations/import",
  },
  {
    title: "Manage my declarations",
    icon: "profile",
    link: "/declarations",
  },
  {
    title: "Manage my beneficiaries",
    icon: "smile",
    link: "/beneficiaries",
  },
  {
    title: "Manage my companies",
    icon: "shop",
    link: "/companies",
  },
];

const Dashboard = () => (
  <Main>
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <div style={{ width: "400px", marginBottom: "20px" }}>
        <div
          style={{
            fontWeight: "700",
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.85)",
            textAlign: "left",
          }}
        >
          What do you want to do?
        </div>
      </div>
      <div style={{ width: "400px", marginBottom: "20px", textAlign: "left" }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Icon type={item.icon} style={{ fontSize: "22px" }} />}
                title={<Link to={item.link}>{item.title}</Link>}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  </Main>
);

export default Dashboard;
