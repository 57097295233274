import "antd/dist/antd.css";

import React from "react";
import { Table, Button, Divider, message } from "antd";
import { Link } from "react-router-dom";
import "url-search-params-polyfill";

import Main from "../components/Main";

import api from "../helpers/api.js";
import util from "../helpers/util.js";

const roles = {
  ROLE_MANAGER: "Manager",
  ROLE_ENCODER: "Encoder",
};

const statuses = {
  active: "Active",
  inactive: "Inactive",
  wait_admin_validation: "Waiting admin validation",
  wait_manager_validation: "Waiting manager validation",
};

function useQuery() {
  return new URLSearchParams(window.location.search);
}

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [
      {
        title: "Name",
        dataIndex: "company.name",
        key: "name",
        sorter: (a, b) => util.sorterString(a.company.name, b.company.name),
      },
      {
        title: "Zip code",
        dataIndex: "company.zipCode",
        key: "zipCode",
        sorter: (a, b) =>
          util.sorterNumber(a.company.zipCode, b.company.zipCode),
      },
      {
        title: "Country",
        dataIndex: "company.country.name",
        key: "country",
        sorter: (a, b) =>
          util.sorterString(a.company.country.name, b.company.country.name),
      },
      {
        title: "Contact first name",
        dataIndex: "company.contactFirstName",
        key: "contactFirstName",
        sorter: (a, b) =>
          util.sorterString(
            a.company.contactFirstName,
            b.company.contactFirstName
          ),
      },
      {
        title: "Contact last name",
        dataIndex: "company.contactLastName",
        key: "contactLastName",
        sorter: (a, b) =>
          util.sorterString(
            a.company.contactLastName,
            b.company.contactLastName
          ),
      },
      {
        title: "Your role",
        dataIndex: "role",
        key: "role",
        render: (text, record) => <span>{roles[text]}</span>,
        sorter: (a, b) => util.sorterString(a.role, b.role),
      },
      {
        title: "Your status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => <span>{statuses[text]}</span>,
        sorter: (a, b) => util.sorterString(a.status, b.status),
      },
      {
        title: "",
        key: "actions",
        render: (text, record) => (
          <span style={{ display: "block", marginBottom: "-10px" }}>
            {record.status === "active" && record.role === "ROLE_MANAGER" ? (
              <Button
                type="primary"
                style={{ marginBottom: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.history.push(
                    `/companies/${record.company.id}/edit`
                  );
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                type="primary"
                style={{ marginBottom: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.history.push(
                    `/companies/${record.company.id}/view`
                  );
                }}
              >
                View
              </Button>
            )}
            {record.status === "active" && record.role === "ROLE_MANAGER" && (
              <>
                <Divider type="vertical" />
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.history.push(
                      `/companies/${record.company.id}/users`
                    );
                  }}
                >
                  Manage users
                </Button>
              </>
            )}
            {record.status === "active" && record.role === "ROLE_MANAGER" && (
              <>
                <Divider type="vertical" />
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.history.push(
                      `/companies/${record.company.id}/declarations`
                    );
                  }}
                >
                  View declarations
                </Button>
              </>
            )}
            {record.company.children.length > 0 &&
              record.status === "active" &&
              record.role === "ROLE_MANAGER" && (
                <>
                  <Divider type="vertical" />
                  <Button
                    style={{ marginBottom: "10px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.history.push({
                        pathname: "/companies",
                        search: `?parent=${record.company.id}`,
                      });
                    }}
                  >
                    View subsidiaries
                  </Button>
                </>
              )}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getCompanies();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getCompanies();
    }
  }

  getCompanies() {
    const parent = useQuery().get("parent");

    api.getMyCompanies((companies) => {
      if (companies) {
        if (parent) {
          const subsidiaries = companies.filter(
            (item) => item.company.parent && item.company.parent.id === parent
          );
          this.setState({ data: subsidiaries });
        } else {
          this.setState({ data: companies });
        }
      } else {
        message.error("An error occurred while retrieving the companies.");
      }
    });
  }

  render() {
    const parent = useQuery().get("parent");

    return (
      <Main>
        <div style={{ marginBottom: "24px" }}>
          <Link to="/companies/new">
            <Button type="primary">Add a new company</Button>
          </Link>
          {parent && (
            <>
              <Divider type="vertical" />
              <Link to="/companies">
                <Button>View all companies</Button>
              </Link>
            </>
          )}
        </div>
        <Table
          columns={this.columns}
          dataSource={this.state.data}
          rowKey={"id"}
          rowClassName="clickable-row"
          onRow={(record) => {
            return {
              onClick: () => {
                if (
                  record.status === "active" &&
                  record.role === "ROLE_MANAGER"
                ) {
                  this.props.history.push(
                    `/companies/${record.company.id}/edit`
                  );
                } else {
                  this.props.history.push(
                    `/companies/${record.company.id}/view`
                  );
                }
              },
            };
          }}
        />
      </Main>
    );
  }
}

export default Companies;
