import "antd/dist/antd.css";

import React from "react";
import { Input, Select, Button, Spin, message, Icon, Tooltip } from "antd";

import Main from "../components/Main";

import api from "../helpers/api.js";
import util from "../helpers/util.js";

const { Option } = Select;

const titles = {
  new: "Add new declaration",
  edit: "Edit declaration",
};

class DeclarationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "new",
      company: undefined,
      companies: null,
      beneficiary: undefined,
      beneficiaries: null,
      year: "",
      amount: "0",
      nature: "support_event",
      description: "",
      currency: undefined,
      currencies: null,
      loading: false,
      ownBeneficiary: true,
    };

    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleBeneficiaryChange = this.handleBeneficiaryChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleNatureChange = this.handleNatureChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    api.getMyCompanies((companies) => {
      if (companies) {
        this.setState({
          companies: companies.filter((item) => item.status === "active"),
        });
      } else {
        message.error("An error occurred while retrieving the companies.");
      }
    });
    api.getMyBeneficiaries((beneficiaries) => {
      if (beneficiaries) {
        this.setState({ beneficiaries }, () => {
          if (this.props.match.params.id) {
            this.setState({
              mode: "edit",
              loading: true,
            });
            api.getDeclaration(this.props.match.params.id, (declaration) => {
              this.setState(
                {
                  company: declaration.company["@id"],
                  beneficiary: declaration.beneficiary["@id"],
                  year: declaration.year,
                  amount: declaration.amount,
                  nature: declaration.nature,
                  description: declaration.description,
                  currency: declaration.currency["@id"],
                  loading: false,
                },
                () => {
                  const ownBeneficiary =
                    this.state.beneficiaries !== null &&
                    this.state.beneficiary !== undefined
                      ? this.state.beneficiaries.findIndex(
                          (item) => item["@id"] === this.state.beneficiary
                        ) !== -1
                      : true;
                  this.setState({ ownBeneficiary });
                  if (!ownBeneficiary) {
                    this.setState({ beneficiaries: [declaration.beneficiary] });
                  }
                }
              );
            });
          }
        });
      } else {
        message.error("An error occurred while retrieving the beneficiaries.");
      }
    });
    api.getAllCurrencies((currencies) => {
      if (currencies) {
        this.setState({ currencies });
      } else {
        message.error("An error occurred while retrieving the currencies.");
      }
    });
  }

  handleCompanyChange(id) {
    this.setState({
      company: id,
    });
  }

  handleBeneficiaryChange(id) {
    this.setState({
      beneficiary: id,
    });
  }

  handleCurrencyChange(id) {
    this.setState({
      currency: id,
    });
  }

  handleNatureChange(id) {
    this.setState({
      nature: id,
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  save() {
    if (this.state.mode === "new") {
      api.createDeclaration(
        {
          company: this.state.company,
          beneficiary: this.state.beneficiary,
          year: parseInt(this.state.year),
          amount: parseFloat(this.state.amount.toString().replace(",", "")),
          nature: this.state.nature,
          description: this.state.description,
          currency: this.state.currency,
        },
        (success, error) => {
          if (success) {
            message.success("The declaration was successfully saved!");
            this.props.history.push(`/dashboard`);
          } else {
            util.displayError(
              "An error occurred while saving the declaration.",
              error["hydra:description"]
            );
          }
        }
      );
    } else {
      api.updateDeclaration(
        this.props.match.params.id,
        {
          company: this.state.company,
          beneficiary: this.state.beneficiary,
          year: parseInt(this.state.year),
          amount: parseFloat(this.state.amount.toString().replace(",", "")),
          nature: this.state.nature,
          description: this.state.description,
          currency: this.state.currency,
        },
        (success, error) => {
          if (success) {
            message.success("The declaration was successfully saved!");
            this.props.history.push(`/declarations`);
          } else {
            util.displayError(
              "An error occurred while saving the declaration.",
              error["hydra:description"]
            );
          }
        }
      );
    }
  }

  render() {
    const optionsCompany = this.state.companies
      ? this.state.companies
          .sort((a, b) => a.company.name.localeCompare(b.company.name))
          .map((d) => (
            <Option key={d.company["@id"]} title={d.company.name}>
              {d.company.name}
            </Option>
          ))
      : "";
    const optionsBeneficiary = this.state.beneficiaries
      ? this.state.beneficiaries
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((d) => (
            <Option key={d["@id"]} title={d.name}>
              {d.name}
            </Option>
          ))
      : "";
    const optionsCurrency = this.state.currencies
      ? this.state.currencies
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((d) => (
            <Option key={d["@id"]} title={d.code}>
              {d.code}
            </Option>
          ))
      : "";

    return (
      <Main>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "16px",
                color: "rgba(0, 0, 0, 0.85)",
                textAlign: "left",
              }}
            >
              {titles[this.state.mode]}
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Select
                name="company"
                value={this.state.company}
                onSelect={this.handleCompanyChange}
                placeholder="Company"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.companies === null}
                disabled={this.state.companies === null}
                autoFocus
                showSearch
                optionFilterProp="title"
              >
                {optionsCompany}
              </Select>
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Select
                name="beneficiary"
                value={this.state.beneficiary}
                onSelect={this.handleBeneficiaryChange}
                placeholder="Beneficiary"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.beneficiaries === null}
                disabled={
                  this.state.beneficiaries === null ||
                  !this.state.ownBeneficiary
                }
                showSearch
                optionFilterProp="title"
              >
                {optionsBeneficiary}
              </Select>
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="year"
                value={this.state.year}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Year"
                min="2000"
                max="2050"
              />
            </div>
            <div
              style={{
                width: "400px",
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                name="amount"
                value={this.state.amount}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Amount"
              />
              <Tooltip title="Use a dot as the decimal separator.">
                <Icon
                  type="info-circle"
                  style={{
                    marginLeft: "10px",
                    cursor: "help",
                  }}
                />
              </Tooltip>
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Select
                name="currency"
                value={this.state.currency}
                onSelect={this.handleCurrencyChange}
                placeholder="Currency"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.currencies === null}
                disabled={this.state.currencies === null}
                showSearch
                optionFilterProp="title"
              >
                {optionsCurrency}
              </Select>
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Select
                name="nature"
                value={this.state.nature}
                onSelect={this.handleNatureChange}
                placeholder="Nature"
                size="large"
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="title"
              >
                <Option
                  key={"support_event"}
                  title="Support to Educational Events"
                >
                  Support to Educational Events
                </Option>
                <Option key={"other_grant"} title="Other Educational Grants">
                  Other Educational Grants
                </Option>
              </Select>
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Comment"
              />
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button type="primary" onClick={this.save}>
                Save
              </Button>
            </div>
          </Spin>
        </div>
      </Main>
    );
  }
}

export default DeclarationForm;
