import "antd/dist/antd.css";

import React from "react";
import { Input, Button, Divider, message } from "antd";
import { Link } from "react-router-dom";
import queryString from "query-string";

import Main from "../components/Main";

import api from "../helpers/api.js";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  login() {
    api.login(
      {
        email: this.state.email,
        password: this.state.password
      },
      success => {
        if (success) {
          const params = queryString.parse(this.props.location.search);
          this.props.history.push(params.next || "/dashboard");
        } else {
          api.checkUser(
            {
              email: this.state.email
            },
            status => {
              if (status === 204) {
                message.error(
                  "It was not possible to login! Your password is incorrect."
                );
              } else if (status === 404) {
                message.error(
                  "It was not possible to login! Your email is incorrect."
                );
              } else if (status === 422) {
                api.forgot(
                  {
                    email: this.state.email
                  },
                  success => {
                    if (success) {
                      message.warning(
                        "We just sent you an email to reset your password."
                      );
                    } else {
                      message.error(
                        "It was not possible to send a request to reset your password! Please make sure your email is associated to an existing account."
                      );
                    }
                  }
                );
              } else {
                message.error("It was not possible to login!");
              }
            }
          );
        }
      }
    );
  }

  render() {
    return (
      <Main>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Input
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              size="large"
              placeholder=""
              addonBefore="Email"
              autoFocus
            />
          </div>
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Input.Password
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              size="large"
              placeholder=""
              addonBefore="Password"
            />
          </div>
          <div
            style={{
              width: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Link to="/forgot">
              <div style={{ lineHeight: "32px" }}>Forgot password?</div>
            </Link>
            <Button type="primary" onClick={this.login}>
              Login
            </Button>
          </div>
          <div style={{ width: "400px" }}>
            <Divider />
          </div>
          <div
            style={{
              width: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div style={{ lineHeight: "32px" }}>Don't have an account yet?</div>
            <Link to="/register">
              <Button type="primary">Register</Button>
            </Link>
          </div>
        </div>
      </Main>
    );
  }
}

export default Login;
