import "antd/dist/antd.css";

import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const Nope = () => (
  <div
    style={{
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    }}
  >
    <Typography>
      <Title>
        Your browser is not supported{" "}
        <span role="img" aria-label="sad face">
          🙁
        </span>
      </Title>
      <Paragraph>
        Please use a recent version of Chrome, Edge, Firefox or Safari.
      </Paragraph>
    </Typography>
  </div>
);

export default Nope;
