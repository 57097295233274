import "antd/dist/antd.css";

import React from "react";
import { Modal } from "antd";

const util = {
  displayError: (title, description) => {
    Modal.error({
      title,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: description.trim().split("\n").join("<br />"),
          }}
        ></div>
      ),
      width: 600,
    });
  },

  sorterString: (a, b) => {
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }
    return a.localeCompare(b);
  },

  sorterNumber: (a, b) => {
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }
    return a - b;
  },

  extractIdFromEndpoint: (endpoint) => {
    return endpoint.substr(endpoint.lastIndexOf("/") + 1);
  },
};

export default util;
