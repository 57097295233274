import "antd/dist/antd.css";

import React from "react";
import { Typography, Descriptions, Button, Modal } from "antd";
import * as algoliasearch from "algoliasearch";

import Main from "../components/Main";

const { Text } = Typography;

const client = algoliasearch("PED0HSXSSJ", "19663fa4acfa283f14c7702d2534f464");
const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME);

const natures = {
  support_event: "Support to Educational Events",
  other_grant: "Other Educational Grants",
};

class DeclarationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }
  componentDidMount() {
    index.getObject(this.props.match.params.id, (err, content) => {
      if (err) throw err;
      this.setState({
        data: content,
      });
    });
  }

  handleClickButton = () => {
    if (this.state.data.contact_url) {
      window.open(this.state.data.contact_url);
    } else {
      Modal.info({
        title: "Contact info",
        content: (
          <div>
            <div>
              <strong>
                {this.state.data.contact_first_name}{" "}
                {this.state.data.contact_last_name}
              </strong>
            </div>
            {this.state.data.contact_email && (
              <div>
                <a href={`mailto:${this.state.data.contact_email}`}>
                  {this.state.data.contact_email}
                </a>
              </div>
            )}
            {this.state.data.contact_phone && (
              <div>
                <span>{this.state.data.contact_phone}</span>
              </div>
            )}
          </div>
        ),
      });
    }
  };

  render() {
    return (
      <Main>
        {this.state.data && (
          <div>
            <div style={{ margin: "0 auto 20px", maxWidth: "800px" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "rgba(0, 0, 0, 0.85)",
                  marginBottom: "20px",
                }}
              >
                Company
              </div>
              <Text>{this.state.data.company_name}</Text>
            </div>
            <div style={{ margin: "0 auto 20px", maxWidth: "800px" }}>
              <Descriptions
                title="Beneficiary"
                layout="vertical"
                bordered
                column="2"
                size="small"
              >
                <Descriptions.Item label="HCO name">
                  {this.state.data.beneficiary_name}
                </Descriptions.Item>
                <Descriptions.Item label="HCO UI">
                  {this.state.data.beneficiary_ui}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  {this.state.data.beneficiary_country}
                </Descriptions.Item>
                <Descriptions.Item label="Zip code">
                  {this.state.data.beneficiary_postal_code}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div style={{ margin: "0 auto 20px", maxWidth: "800px" }}>
              <Descriptions
                title="Declaration"
                layout="vertical"
                bordered
                size="small"
              >
                <Descriptions.Item label="Declaration year">
                  {this.state.data.declaration_year}
                </Descriptions.Item>
                <Descriptions.Item label="Amount">
                  {this.state.data.declaration_amount}{" "}
                  {this.state.data.declaration_currency}
                </Descriptions.Item>
                <Descriptions.Item label="Nature">
                  {natures[this.state.data.declaration_nature]}
                </Descriptions.Item>
                <Descriptions.Item label="Comments">
                  {this.state.data.declaration_comments || "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div style={{ margin: "40px auto 20px", maxWidth: "800px" }}>
              <Button onClick={this.handleClickButton}>Report abuse</Button>
            </div>
          </div>
        )}
      </Main>
    );
  }
}

export default DeclarationDetail;
