import "antd/dist/antd.css";

import React from "react";
import { Table } from "antd";
import * as algoliasearch from "algoliasearch";

import Main from "../components/Main";

import util from "../helpers/util.js";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);
const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME);

const natures = {
  support_event: "Support to Educational Events",
  other_grant: "Other Educational Grants",
};

const columns = [
  {
    title: "Company name",
    dataIndex: "company_name",
    key: "company_name",
    sorter: (a, b) => util.sorterString(a.company_name, b.company_name),
  },
  {
    title: "Company UI",
    dataIndex: "company_ui",
    key: "company_ui",
    sorter: (a, b) => util.sorterString(a.company_ui, b.company_ui),
  },
  {
    title: "Beneficiary name",
    dataIndex: "beneficiary_name",
    key: "beneficiary_name",
    sorter: (a, b) => util.sorterString(a.beneficiary_name, b.beneficiary_name),
  },
  {
    title: "Beneficiary UI",
    dataIndex: "beneficiary_ui",
    key: "beneficiary_ui",
    sorter: (a, b) => util.sorterString(a.beneficiary_ui, b.beneficiary_ui),
  },
  {
    title: "Year",
    dataIndex: "declaration_year",
    key: "declaration_year",
    sorter: (a, b) => util.sorterNumber(a.declaration_year, b.declaration_year),
  },
  {
    title: "Nature",
    dataIndex: "declaration_nature",
    key: "declaration_nature",
    render: (text, record) => <span>{natures[text]}</span>,
    sorter: (a, b) =>
      util.sorterString(a.declaration_nature, b.declaration_nature),
  },
  {
    title: "Amount",
    dataIndex: "declaration_amount",
    key: "declaration_amount",
    render: (text, record) => (
      <span>
        {record.declaration_amount} {record.declaration_currency}
      </span>
    ),
    sorter: (a, b) =>
      util.sorterNumber(a.declaration_amount, b.declaration_amount),
  },
];

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.search(this.props.match.params.q);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.q !== this.props.match.params.q) {
      this.search(this.props.match.params.q);
    }
  }

  search(query) {
    index.search({ query: query, hitsPerPage: 1000 }, (err, { hits } = {}) => {
      if (err) throw err;
      this.setState({
        data: hits,
      });
    });
  }

  render() {
    return (
      <Main query={decodeURIComponent(this.props.match.params.q)}>
        <Table
          columns={columns}
          dataSource={this.state.data}
          rowKey={"objectID"}
          rowClassName="clickable-row"
          onRow={(record) => {
            return {
              onClick: () => {
                this.props.history.push(`/detail/${record.objectID}`);
              },
            };
          }}
        />
      </Main>
    );
  }
}

export default Search;
