import "antd/dist/antd.css";

import React from "react";
import { Descriptions } from "antd";

import Main from "../components/Main";

import api from "../helpers/api.js";

class CompanyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }
  componentDidMount() {
    api.getCompany(this.props.match.params.id, (company) => {
      this.setState({
        data: company,
      });
    });
  }
  render() {
    return (
      <Main>
        {this.state.data && (
          <div>
            <div style={{ margin: "0 auto 20px", maxWidth: "800px" }}>
              <Descriptions
                title="General"
                layout="vertical"
                bordered
                column="2"
                size="small"
              >
                <Descriptions.Item label="Name">
                  {this.state.data.name}
                </Descriptions.Item>
                <Descriptions.Item label="Zip code">
                  {this.state.data.zipCode || "N/A"}
                </Descriptions.Item>
                {this.state.data.country && (
                  <Descriptions.Item label="Country">
                    {this.state.data.country.name}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
            <div style={{ margin: "0 auto 20px", maxWidth: "800px" }}>
              <Descriptions
                title="Contact"
                layout="vertical"
                bordered
                column="2"
                size="small"
              >
                <Descriptions.Item label="First name">
                  {this.state.data.contactFirstName || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Last name">
                  {this.state.data.contactLastName || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {this.state.data.contactEmail || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                  {this.state.data.contactPhone || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="URL">
                  {this.state.data.contactUrl || "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        )}
      </Main>
    );
  }
}

export default CompanyDetail;
