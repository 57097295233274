import "antd/dist/antd.css";

import React from "react";
import { Table, Button, Input, Divider, message } from "antd";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import Fuse from "fuse.js";
import * as Papa from "papaparse";

import Main from "../components/Main";

import api from "../helpers/api.js";
import util from "../helpers/util.js";

const { Search } = Input;

const natures = {
  support_event: "Support to Educational Events",
  other_grant: "Other Educational Grants",
};

const statuses = {
  draft: "Draft",
  published: "Published",
  blocked: "Blocked",
};

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      filteredData: [],
    };
    this.columns = [
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        render: (text, record) => (
          <span>
            {record.user.firstName} {record.user.lastName}
          </span>
        ),
        sorter: (a, b) => util.sorterString(a.user.firstName, b.user.lastName),
      },
      {
        title: "Company",
        dataIndex: "company.name",
        key: "company",
        sorter: (a, b) => util.sorterString(a.company.name, b.company.name),
      },
      {
        title: "Beneficiary",
        dataIndex: "beneficiary.name",
        key: "beneficiary",
        sorter: (a, b) =>
          util.sorterString(a.beneficiary.name, b.beneficiary.name),
      },
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
        sorter: (a, b) => util.sorterNumber(a.year, b.year),
      },
      {
        title: "Nature",
        dataIndex: "nature",
        key: "nature",
        render: (text, record) => <span>{natures[text]}</span>,
        sorter: (a, b) => util.sorterString(a.nature, b.nature),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => (
          <span>
            {record.amount} {record.currency.code}
          </span>
        ),
        sorter: (a, b) => util.sorterNumber(a.amount, b.amount),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => <span>{statuses[text]}</span>,
        sorter: (a, b) => util.sorterString(a.status, b.status),
      },
      {
        title: "",
        key: "actions",
        render: (text, record) => (
          <span style={{ display: "block", marginBottom: "-10px" }}>
            <Button
              type="primary"
              style={{ marginBottom: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`/declarations/${record.id}/edit`);
              }}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            {record.status !== "blocked" ? (
              <Button
                style={{ marginBottom: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.disable(record.id);
                }}
              >
                Disable
              </Button>
            ) : (
              <Button
                style={{ marginBottom: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.enable(record.id);
                }}
              >
                Enable
              </Button>
            )}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getCompanyDeclarations();
    } else {
      this.getMyDeclarations();
    }
  }

  handleClickExport = () => {
    const data = [];
    this.state.allData.forEach((declaration) => {
      data.push({
        Company: declaration.company.name,
        Beneficiary: declaration.beneficiary.name,
        Year: declaration.year,
        Amount: declaration.amount,
        Currency: declaration.currency.code,
        Nature: natures[declaration.nature],
        Comment: declaration.description || "",
      });
    });
    const content = Papa.unparse(data);
    const blob = new Blob([content], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "export.csv");
  };

  handleChange = (e) => {
    const query = e.target.value;
    const allDeclarations = this.state.allData;
    let filteredDeclarations;
    if (query !== "") {
      const fuse = new Fuse(allDeclarations, {
        keys: [
          {
            name: "company.name",
            weight: 0.9,
          },
          {
            name: "beneficiary.name",
            weight: 0.8,
          },
          {
            name: "year",
            weight: 0.7,
          },
          {
            name: "amount",
            weight: 0.6,
          },
          {
            name: "user.firstName",
            weight: 0.5,
          },
          {
            name: "user.lastName",
            weight: 0.5,
          },
        ],
        minMatchCharLength: 1,
        threshold: 0.2,
      });
      filteredDeclarations = fuse.search(query).map((result) => result.item);
    } else {
      filteredDeclarations = allDeclarations;
    }
    this.setState({
      filteredData: filteredDeclarations,
    });
  };

  getMyDeclarations() {
    api.getMyDeclarations((declarations) => {
      if (declarations) {
        this.setState({ allData: declarations, filteredData: declarations });
      } else {
        message.error("An error occurred while retrieving the declarations.");
      }
    });
  }

  getCompanyDeclarations() {
    api.getCompanyDeclarations(this.props.match.params.id, (declarations) => {
      if (declarations) {
        this.setState({ allData: declarations, filteredData: declarations });
      } else {
        message.error("An error occurred while retrieving the declarations.");
      }
    });
  }

  updateStatus(id, status) {
    api.updateDeclaration(
      id,
      {
        status: status,
      },
      (success) => {
        if (success) {
          message.success("The declaration was successfully saved!");
          window.location.reload();
        } else {
          message.error("An error occurred while saving the declaration.");
        }
      }
    );
  }

  enable(id) {
    this.updateStatus(id, "draft");
  }

  disable(id) {
    this.updateStatus(id, "blocked");
  }

  render() {
    return (
      <Main>
        <div style={{ marginBottom: "24px" }}>
          <Link to="/declarations/new">
            <Button type="primary">Add a new declaration</Button>
          </Link>
          <Divider type="vertical" />
          <Button onClick={this.handleClickExport}>
            Export all declarations
          </Button>
        </div>
        <div style={{ marginBottom: "24px", maxWidth: "600px" }}>
          <Search
            placeholder="Filter declarations"
            onChange={this.handleChange}
          />
        </div>
        <Table
          columns={this.columns}
          dataSource={this.state.filteredData}
          rowKey={"id"}
          rowClassName="clickable-row"
          onRow={(record) => {
            return {
              onClick: () => {
                this.props.history.push(`/declarations/${record.id}/edit`);
              },
            };
          }}
        />
      </Main>
    );
  }
}

export default List;
