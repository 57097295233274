import "antd/dist/antd.css";

import React from "react";
import { Layout, Input, Button, Icon, Popconfirm } from "antd";
import { withRouter, Link } from "react-router-dom";

import Logo from "./Logo";
import SearchWrapper from "./SearchWrapper";
import Wrapper from "./Wrapper";

import api from "../helpers/api.js";

const { Header, Footer, Content } = Layout;
const { Search } = Input;

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
  }

  confirm(e) {
    this.props.history.push("/logout");
  }

  render() {
    return (
      <Layout style={{ height: "100vh" }}>
        <Header>
          <Wrapper>
            <Link to="/">
              <Logo>Transparent MedTech</Logo>
            </Link>
            <SearchWrapper>
              <Search
                defaultValue={this.props.query}
                placeholder="Your search here..."
                onSearch={(value) => {
                  if (value !== "") {
                    this.props.history.push(
                      `/search/${encodeURIComponent(value)}`
                    );
                  }
                }}
                enterButton
                size="large"
              />
            </SearchWrapper>
            {!api.isAuthenticated() ? (
              <Link to="/login">
                <Button type="primary">Company access</Button>
              </Link>
            ) : (
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                  <Link to="/dashboard">
                    <Icon type="dashboard" style={{ fontSize: "22px" }} />
                  </Link>
                </div>
                <div style={{ marginRight: "10px" }}>
                  <Link to="/profile">
                    <Icon type="user" style={{ fontSize: "22px" }} />
                  </Link>
                </div>
                <div>
                  <Popconfirm
                    title="Are you sure you want to sign out?"
                    onConfirm={this.confirm}
                    okText="Yes"
                    cancelText="No"
                    placement="bottomRight"
                  >
                    <Link to="/logout">
                      <Icon type="logout" style={{ fontSize: "22px" }} />
                    </Link>
                  </Popconfirm>
                </div>
              </div>
            )}
          </Wrapper>
        </Header>
        <Content style={{ padding: "0 50px", height: "100%" }}>
          <div
            style={{
              background: "#fff",
              padding: 24,
              height: "100%",
              overflow: "auto",
            }}
          >
            {this.props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Transparent MedTech &copy; {new Date().getFullYear()}
        </Footer>
      </Layout>
    );
  }
}

export default withRouter(Main);
