import "antd/dist/antd.css";

import React from "react";
import { Input, Select, Button, Spin, message } from "antd";

import Main from "../components/Main";

import api from "../helpers/api.js";
import util from "../helpers/util.js";

const { Option } = Select;

const titles = {
  new: "Add new company",
  edit: "Edit company",
};

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "new",
      name: "",
      zipCode: "",
      country: undefined,
      countries: null,
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      contactUrl: "",
      company: undefined,
      companies: null,
      allCompanies: null,
      loading: false,
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      api.isCompanyManager(this.props.match.params.id, (result) => {
        if (!result) {
          this.props.history.push(`/dashboard`);
        }
      });
    }
  }

  componentDidMount() {
    api.getMyCompanies((companies) => {
      if (companies) {
        this.setState(
          {
            companies: companies.filter((item) => item.status === "active"),
            allCompanies: companies,
          },
          () => {
            if (this.props.match.params.id) {
              this.setState({
                mode: "edit",
                loading: true,
              });
              api.getCompany(this.props.match.params.id, (company) => {
                this.setState({
                  name: company.name,
                  zipCode: company.zipCode,
                  country: company.country ? company.country["@id"] : undefined,
                  contactFirstName: company.contactFirstName,
                  contactLastName: company.contactLastName,
                  contactEmail: company.contactEmail,
                  contactPhone: company.contactPhone,
                  contactUrl: company.contactUrl,
                  company: company.parent ? company.parent["@id"] : undefined,
                  loading: false,
                });
                if (
                  company.parent &&
                  !this.state.companies.find(
                    (item) => item["@id"] === company.parent["@id"]
                  )
                ) {
                  this.setState({
                    companies: [
                      ...this.state.companies,
                      { company: company.parent },
                    ],
                  });
                }
              });
            }
          }
        );
      } else {
        message.error("An error occurred while retrieving the companies.");
      }
    });
    api.getAllCountries((countries) => {
      if (countries) {
        this.setState({ countries });
      } else {
        message.error("An error occurred while retrieving the countries.");
      }
    });
  }

  handleCountryChange(id) {
    this.setState({
      country: id,
    });
  }

  handleCompanyChange(id) {
    this.setState({
      company: id,
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  save() {
    if (this.state.mode === "new") {
      api.createCompany(
        {
          name: this.state.name,
          zipCode: this.state.zipCode,
          country: this.state.country,
          contactFirstName: this.state.contactFirstName,
          contactLastName: this.state.contactLastName,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone,
          contactUrl: this.state.contactUrl,
          parent: this.state.company,
        },
        (result, error) => {
          if (result) {
            api.getCurrentUser((user) => {
              const companies = [
                ...this.state.allCompanies.map((item) => item.company["@id"]),
                result["@id"],
              ];
              api.updateUser(
                user.id,
                {
                  companies,
                },
                () => {
                  message.success("The company was successfully saved!");
                  this.props.history.push(`/dashboard`);
                }
              );
            });
          } else {
            util.displayError(
              "An error occurred while saving the company.",
              error["hydra:description"]
            );
          }
        }
      );
    } else {
      api.updateCompany(
        this.props.match.params.id,
        {
          name: this.state.name,
          zipCode: this.state.zipCode,
          country: this.state.country,
          contactFirstName: this.state.contactFirstName,
          contactLastName: this.state.contactLastName,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone,
          contactUrl: this.state.contactUrl,
          parent: this.state.company,
        },
        (success, error) => {
          if (success) {
            message.success("The company was successfully saved!");
            this.props.history.push(`/companies`);
          } else {
            util.displayError(
              "An error occurred while saving the company.",
              error["hydra:description"]
            );
          }
        }
      );
    }
  }

  render() {
    const optionsCountry = this.state.countries
      ? this.state.countries
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((d) => (
            <Option key={d["@id"]} title={d.name}>
              {d.name}
            </Option>
          ))
      : "";

    const optionsCompany = this.state.companies
      ? this.state.companies
          .filter((d) => d.company.id !== this.props.match.params.id)
          .sort((a, b) => a.company.name.localeCompare(b.company.name))
          .map((d) => (
            <Option key={d.company["@id"]} title={d.company.name}>
              {d.company.name}
            </Option>
          ))
      : "";

    return (
      <Main>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "16px",
                color: "rgba(0, 0, 0, 0.85)",
                textAlign: "left",
              }}
            >
              {titles[this.state.mode]}
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Name"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="zipCode"
                value={this.state.zipCode}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Zip code"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Select
                name="country"
                value={this.state.country}
                onSelect={this.handleCountryChange}
                placeholder="Country"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.countries === null}
                disabled={this.state.countries === null}
                showSearch
                optionFilterProp="title"
              >
                {optionsCountry}
              </Select>
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="contactFirstName"
                value={this.state.contactFirstName}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Contact first name"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="contactLastName"
                value={this.state.contactLastName}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Contact last name"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="contactEmail"
                value={this.state.contactEmail}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Contact email"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="contactPhone"
                value={this.state.contactPhone}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Contact phone"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="contactUrl"
                value={this.state.contactUrl}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Contact URL"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Select
                name="company"
                value={this.state.company}
                onSelect={this.handleCompanyChange}
                placeholder="Parent company"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.companies === null}
                disabled={this.state.companies === null}
                autoFocus
                showSearch
                optionFilterProp="title"
              >
                {optionsCompany}
              </Select>
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button type="primary" onClick={this.save}>
                Save
              </Button>
            </div>
          </Spin>
        </div>
      </Main>
    );
  }
}

export default CompanyForm;
