import axios from "axios";

const API_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : "/api";

const api = {
  isAuthenticated() {
    return !!localStorage.getItem("jwt");
  },

  isActive() {
    const status = localStorage.getItem("status");
    return status === "active";
  },

  async isCompanyManager(id, cb) {
    try {
      const result = await axios.get(
        `${API_URL}/users/me/user_companies?pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(
        result.data["hydra:member"].some(
          (item) =>
            item.company.id === id &&
            item.status === "active" &&
            item.role === "ROLE_MANAGER"
        )
      );
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async login(data, cb) {
    try {
      const response1 = await axios.post(
        `${API_URL}/authentication_token`,
        {
          email: data.email,
          password: data.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("jwt", response1.data.token);

      const response2 = await axios.get(`${API_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      localStorage.setItem("status", response2.data.status);

      cb(true);
    } catch (err) {
      cb(false, err.response.data);
    }
  },

  async checkUser(data, cb) {
    try {
      const result = await axios.get(
        `${API_URL}/users/check?email=${encodeURIComponent(data.email)}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.status);
    } catch (err) {
      cb(err.response.status);
    }
  },

  async getCurrentUser(cb) {
    try {
      const result = await axios.get(`${API_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async createUser(data, cb) {
    try {
      const result = await axios.post(
        `${API_URL}/users`,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          plainPassword: data.password,
          companies: data.companies,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async updateUser(id, data, cb) {
    try {
      await axios.put(`${API_URL}/users/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(true);
    } catch (err) {
      cb(false, err.response.data);
    }
  },

  async updateUserCompany(id, data, cb) {
    try {
      await axios.put(`${API_URL}/user_companies/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(true);
    } catch (err) {
      cb(false, err.response.data);
    }
  },

  async forgot(data, cb) {
    try {
      await axios.post(
        `${API_URL}/forgot`,
        {
          email: data.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      cb(true);
    } catch (err) {
      cb(false, err.response.data);
    }
  },

  async reset(data, cb) {
    try {
      await axios.post(
        `${API_URL}/reset`,
        {
          plainPassword: data.password,
          token: data.token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      cb(true);
    } catch (err) {
      cb(false, err.response.data);
    }
  },

  async getMyDeclarations(cb) {
    try {
      const result = await axios.get(
        `${API_URL}/users/me/declarations?pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getCompanyDeclarations(id, cb) {
    try {
      const result = await axios.get(
        `${API_URL}/companies/${id}/declarations?pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getMyCompanies(cb) {
    try {
      const result = await axios.get(
        `${API_URL}/users/me/user_companies?pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getMyBeneficiaries(cb) {
    try {
      const result = await axios.get(
        `${API_URL}/users/me/beneficiaries?pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getAllCompanies(cb) {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const token = localStorage.getItem("jwt");
      if (token) {
        headers["Authorization"] = `Bearer ${localStorage.getItem("jwt")}`;
      }
      const result = await axios.get(`${API_URL}/companies?pagination=false`, {
        headers,
      });
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getAllCurrencies(cb) {
    try {
      const result = await axios.get(`${API_URL}/currencies?pagination=false`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getAllCountries(cb) {
    try {
      const result = await axios.get(
        `${API_URL}/countries?status=active&pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async createCompany(data, cb) {
    try {
      const result = await axios.post(`${API_URL}/companies`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getCompany(id, cb) {
    try {
      const result = await axios.get(`${API_URL}/companies/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async getCompanyUsers(id, cb) {
    try {
      const result = await axios.get(
        `${API_URL}/companies/${id}/user_companies`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data["hydra:member"]);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async updateCompany(id, data, cb) {
    try {
      await axios.put(`${API_URL}/companies/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(true);
    } catch (err) {
      cb(false, err.response.data);
    }
  },

  async createDeclaration(data, cb) {
    try {
      const result = await axios.post(
        `${API_URL}/declarations`,
        {
          company: data.company,
          beneficiary: data.beneficiary,
          year: data.year,
          amount: data.amount,
          nature: data.nature,
          description: data.description,
          currency: data.currency,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async updateDeclaration(id, data, cb) {
    try {
      await axios.put(`${API_URL}/declarations/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(true);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async bulkDeclarations(data, cb) {
    try {
      await axios.post(
        `${API_URL}/bulk_declarations`,
        {
          company: data.company,
          declarations: data.declarations,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(true);
    } catch (err) {
      cb(false, err.response.data);
    }
  },

  async getDeclaration(id, cb) {
    try {
      const result = await axios.get(`${API_URL}/declarations/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async createBeneficiary(data, cb) {
    try {
      const result = await axios.post(
        `${API_URL}/beneficiaries`,
        {
          name: data.name,
          address: data.address,
          zipCode: data.zipCode,
          city: data.city,
          country: data.country,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async updateBeneficiary(id, data, cb) {
    try {
      await axios.put(`${API_URL}/beneficiaries/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(true);
    } catch (err) {
      cb(false);
    }
  },

  async getBeneficiary(id, cb) {
    try {
      const result = await axios.get(`${API_URL}/beneficiaries/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(result.data);
    } catch (err) {
      cb(null, err.response.data);
    }
  },

  async deleteBeneficiary(id, cb) {
    try {
      await axios.delete(`${API_URL}/beneficiaries/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });
      cb(true);
    } catch (err) {
      cb(false, err.response.status);
    }
  },

  async checkBeneficiary(data, cb) {
    try {
      const result = await axios.get(
        `${API_URL}/users/me/beneficiaries?name=${encodeURIComponent(
          data.name
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      cb(result.data["hydra:member"].length > 0);
    } catch (err) {
      cb(null, false);
    }
  },

  signout() {
    localStorage.clear();
  },
};

export default api;
