import "antd/dist/antd.css";

import React from "react";
import { Input, Select, Button, message } from "antd";

import Main from "../components/Main";

import api from "../helpers/api.js";

const { Option } = Select;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompanies: [],
      companies: null,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: ""
    };

    this.handleCompanySelect = this.handleCompanySelect.bind(this);
    this.handleCompanyDeselect = this.handleCompanyDeselect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    api.getAllCompanies(companies => {
      if (companies) {
        this.setState({ companies });
      } else {
        message.error("An error occurred while retrieving the companies.");
      }
    });
  }

  handleCompanySelect(id) {
    this.setState({
      selectedCompanies: [...this.state.selectedCompanies, id]
    });
  }

  handleCompanyDeselect(id) {
    const index = this.state.selectedCompanies.indexOf(id);
    const beforeCompanies = this.state.selectedCompanies.slice(0, index);
    const afterCompanies = this.state.selectedCompanies.slice(index + 1);

    if (index > -1) {
      this.setState({
        selectedCompanies: [...beforeCompanies, ...afterCompanies]
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  register() {
    const existingCompanies = this.state.selectedCompanies.filter(
      company => company.indexOf("/api/companies/") === 0
    );
    const newCompanies = this.state.selectedCompanies.filter(
      company => company.indexOf("/api/companies/") === -1
    );

    api.createUser(
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        password: this.state.password,
        companies: existingCompanies
      },
      result => {
        if (result) {
          api.login(
            {
              email: this.state.email,
              password: this.state.password
            },
            () => {
              const promises = [];

              newCompanies.forEach(company => {
                const promise = new Promise(resolve => {
                  api.createCompany(
                    {
                      name: company
                    },
                    result => {
                      resolve(result["@id"]);
                    }
                  );
                });
                promises.push(promise);
              });

              Promise.all(promises).then(values => {
                const allCompanies = [...existingCompanies, ...values];

                api.updateUser(
                  result.id,
                  {
                    companies: allCompanies
                  },
                  () => {
                    this.props.history.push(`/dashboard`);
                  }
                );
              });
            }
          );
        } else {
          message.error(
            "It was not possible to register! This might be because this email address has already been used for another account."
          );
        }
      }
    );
  }

  render() {
    const optionsCompany = this.state.companies
      ? this.state.companies
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(d => (
            <Option key={d["@id"]} title={d.name}>
              {d.name}
            </Option>
          ))
      : "";

    return (
      <Main>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Input
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleChange}
              size="large"
              placeholder=""
              addonBefore="First name"
              autoFocus
            />
          </div>
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Input
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleChange}
              size="large"
              placeholder=""
              addonBefore="Last name"
            />
          </div>
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Input
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              size="large"
              placeholder=""
              addonBefore="Email"
            />
          </div>
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Input
              name="phone"
              value={this.state.phone}
              onChange={this.handleChange}
              size="large"
              placeholder=""
              addonBefore="Phone"
            />
          </div>
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Input.Password
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              size="large"
              placeholder=""
              addonBefore="Password"
            />
          </div>
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Select
              optionFilterProp="title"
              mode="tags"
              name="company"
              value={this.state.selectedCompanies}
              onSelect={this.handleCompanySelect}
              onDeselect={this.handleCompanyDeselect}
              placeholder="Companies"
              size="large"
              style={{ width: "100%" }}
              loading={this.state.companies === null}
              disabled={this.state.companies === null}
              autoFocus
              showSearch
            >
              {optionsCompany}
            </Select>
          </div>
          <div
            style={{
              width: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <Button type="primary" onClick={this.register}>
              Register
            </Button>
          </div>
        </div>
      </Main>
    );
  }
}

export default Register;
