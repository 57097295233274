import "antd/dist/antd.css";

import React from "react";
import { Input, Select, Button, Spin, message, Modal } from "antd";

import Main from "../components/Main";

import api from "../helpers/api.js";
import util from "../helpers/util.js";

const { Option } = Select;
const { confirm } = Modal;

const titles = {
  new: "Add new beneficiary",
  edit: "Edit beneficiary",
};

class BeneficiaryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "new",
      name: "",
      address: "",
      zipCode: "",
      city: "",
      country: undefined,
      uniqueIdentifier: "",
      countries: null,
      loading: false,
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    api.getAllCountries((countries) => {
      if (countries) {
        this.setState({ countries });
      } else {
        message.error("An error occurred while retrieving the countries.");
      }
    });
    if (this.props.match.params.id) {
      this.setState({
        mode: "edit",
        loading: true,
      });
      api.getBeneficiary(this.props.match.params.id, (beneficiary) => {
        this.setState({
          name: beneficiary.name,
          address: beneficiary.address,
          zipCode: beneficiary.zipCode,
          city: beneficiary.city,
          country: beneficiary.country["@id"],
          uniqueIdentifier: beneficiary.uniqueIdentifier,
          loading: false,
        });
      });
    }
  }

  handleCountryChange(id) {
    this.setState({
      country: id,
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  createBeneficiary() {
    api.createBeneficiary(
      {
        name: this.state.name,
        address: this.state.address,
        zipCode: this.state.zipCode,
        city: this.state.city,
        country: this.state.country,
        uniqueIdentifier: this.state.uniqueIdentifier,
      },
      (success, error) => {
        if (success) {
          message.success("The beneficiary was successfully saved!");
          this.props.history.push(`/dashboard`);
        } else {
          util.displayError(
            "An error occurred while saving the beneficiary.",
            error["hydra:description"]
          );
        }
      }
    );
  }

  save() {
    if (this.state.mode === "new") {
      api.checkBeneficiary({ name: this.state.name }, (found) => {
        if (!found) {
          this.createBeneficiary();
        } else {
          confirm({
            title: "Already existing beneficiary with that name",
            content: `We found at least one beneficiary with a name that matches "${this.state.name}". Do you really want to proceed with the creation of this beneficiary?`,
            onOk: () => {
              this.createBeneficiary();
            },
            onCancel: () => {
              this.props.history.push(`/beneficiaries`);
            },
          });
        }
      });
    } else {
      api.updateBeneficiary(
        this.props.match.params.id,
        {
          name: this.state.name,
          address: this.state.address,
          zipCode: this.state.zipCode,
          city: this.state.city,
          country: this.state.country,
          uniqueIdentifier: this.state.uniqueIdentifier,
        },
        (success, error) => {
          if (success) {
            message.success("The beneficiary was successfully saved!");
            this.props.history.push(`/beneficiaries`);
          } else {
            util.displayError(
              "An error occurred while saving the beneficiary.",
              error["hydra:description"]
            );
          }
        }
      );
    }
  }

  render() {
    const optionsCountry = this.state.countries
      ? this.state.countries
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((d) => (
            <Option key={d["@id"]} title={d.name}>
              {d.name}
            </Option>
          ))
      : "";

    return (
      <Main>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: "16px",
                color: "rgba(0, 0, 0, 0.85)",
                textAlign: "left",
              }}
            >
              {titles[this.state.mode]}
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Name"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Address"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="zipCode"
                value={this.state.zipCode}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Zip code"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="city"
                value={this.state.city}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="City"
              />
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Select
                name="country"
                value={this.state.country}
                onSelect={this.handleCountryChange}
                placeholder="Country"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.countries === null}
                disabled={this.state.countries === null}
                showSearch
                optionFilterProp="title"
              >
                {optionsCountry}
              </Select>
            </div>
            <div style={{ width: "400px", marginBottom: "20px" }}>
              <Input
                name="uniqueIdentifier"
                value={this.state.uniqueIdentifier}
                onChange={this.handleChange}
                size="large"
                placeholder=""
                addonBefore="Unique identifier"
              />
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button type="primary" onClick={this.save}>
                Save
              </Button>
            </div>
          </Spin>
        </div>
      </Main>
    );
  }
}

export default BeneficiaryForm;
