import "antd/dist/antd.css";

import React from "react";
import { Upload, Select, message, Button, Icon, Modal } from "antd";
import * as Papa from "papaparse";
import jschardet from "jschardet";

import Main from "../components/Main";

import api from "../helpers/api.js";

const { Option } = Select;

const MANDATORY_FIELDS = [
  "BENEFICIARY_NAME",
  "BENEFICIARY_CITY",
  "BENEFICIARY_COUNTRY_CODE",
  "BENEFICIARY_POSTAL_CODE",
  "BENEFICIARY_ADDRESS",
  "DECLARATION_YEAR",
  "EDUCATIONAL_GRANT_TYPE",
  "AMOUNT",
  "CURRENCY_CODE",
];
const MAX_DISPLAYED_ERRORS = 10;

const getProps = (company, cb) => {
  return {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false,
    beforeUpload(file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        /*if (jschardet.detect(e.target.result).encoding === "ascii") {
          Modal.error({
            title: `Incorrect character encoding.`,
            content: (
              <div>
                Make sure that your file has been saved using the UTF-8
                character encoding.
              </div>
            ),
            width: 600,
          });
        } else {*/
          const data = Papa.parse(e.target.result, {
            header: true,
            skipEmptyLines: true,
          });
          if (data.errors.length > 0) {
            const errorsArray = data.errors;
            Modal.error({
              title: `${file.name} is not a well-formed CSV file.`,
              content: (
                <ul>
                  {errorsArray
                    .slice(0, MAX_DISPLAYED_ERRORS)
                    .map((error, index) => {
                      return (
                        <li key={index}>
                          <strong>Row {error.row + 2}</strong>: {error.message}
                        </li>
                      );
                    })}
                  {errorsArray.length > MAX_DISPLAYED_ERRORS && (
                    <li>
                      + {errorsArray.length - MAX_DISPLAYED_ERRORS} more error
                      {errorsArray.length - MAX_DISPLAYED_ERRORS > 1 && "s"}
                    </li>
                  )}
                </ul>
              ),
              width: 600,
            });
          } else {
            var success = MANDATORY_FIELDS.every(function (val) {
              return data.meta.fields.indexOf(val) !== -1;
            });
            if (success) {
              const declarations = data.data.map((item) => ({
                ...item,
                AMOUNT: item.AMOUNT.replace(/,/g, ""),
              }));
              api.bulkDeclarations(
                {
                  company,
                  declarations,
                },
                (success, error) => {
                  if (success) {
                    message.success(
                      "The declarations were successfully saved!"
                    );
                    cb();
                  } else {
                    const errorsArray = error["hydra:description"]
                      .trim()
                      .split("\n");
                    Modal.error({
                      title: `${file.name} has some invalid data.`,
                      content: (
                        <ul>
                          {errorsArray
                            .slice(0, MAX_DISPLAYED_ERRORS)
                            .map((error, index) => {
                              const htmlError = `<strong>${error.replace(
                                ":",
                                "</strong>:"
                              )}`;
                              return (
                                <li
                                  key={index}
                                  dangerouslySetInnerHTML={{
                                    __html: htmlError,
                                  }}
                                />
                              );
                            })}
                          {errorsArray.length > MAX_DISPLAYED_ERRORS && (
                            <li>
                              + {errorsArray.length - MAX_DISPLAYED_ERRORS} more
                              error
                              {errorsArray.length - MAX_DISPLAYED_ERRORS > 1 &&
                                "s"}
                            </li>
                          )}
                        </ul>
                      ),
                      width: 600,
                    });
                  }
                }
              );
            } else {
              Modal.error({
                title: `Missing mandatory fields.`,
                content: (
                  <div>
                    Make sure the first line of the CSV files contains the field
                    names.
                    <br />
                    <br />
                    Mandatory fields are: {MANDATORY_FIELDS.join(", ")}.
                  </div>
                ),
                width: 600,
              });
            }
          }
        //}
      };
      reader.readAsText(file, "UTF-8");
      return false;
    },
  };
};

class Import extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: undefined,
      companies: null,
    };

    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  componentDidMount() {
    api.getMyCompanies((companies) => {
      if (companies) {
        this.setState({
          companies: companies.filter((item) => item.status === "active"),
        });
      } else {
        message.error("An error occurred while retrieving the companies.");
      }
    });
  }

  handleCompanyChange(id) {
    this.setState({
      company: id,
    });
  }

  handleClickDownload = () => {
    window.location.href = `${process.env.PUBLIC_URL}/declaration-csv-template.csv`;
  };

  render() {
    const optionsCompany = this.state.companies
      ? this.state.companies
          .sort((a, b) => a.company.name.localeCompare(b.company.name))
          .map((d) => (
            <Option key={d.company["@id"]} title={d.company.name}>
              {d.company.name}
            </Option>
          ))
      : "";

    return (
      <Main>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "400px", marginBottom: "20px" }}>
            <Select
              name="company"
              value={this.state.company}
              onSelect={this.handleCompanyChange}
              placeholder="Company"
              size="large"
              style={{ width: "100%" }}
              loading={this.state.companies === null}
              disabled={this.state.companies === null}
              autoFocus
              showSearch
              optionFilterProp="title"
            >
              {optionsCompany}
            </Select>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Upload
              {...getProps(this.state.company, () => {
                this.props.history.push(`/dashboard`);
              })}
            >
              <Button disabled={!this.state.company} type="primary">
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>
          </div>
          <Button type="link" onClick={this.handleClickDownload}>
            <Icon type="download" /> Download sample
          </Button>
        </div>
      </Main>
    );
  }
}

export default Import;
