import React from "react";
import { Route, Redirect } from "react-router-dom";

import api from "../helpers/api.js";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      api.isAuthenticated() === true ? (
        api.isActive() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/waiting" />
        )
      ) : (
        <Redirect
          to={`/login?next=${encodeURIComponent(rest.computedMatch.url)}`}
        />
      )
    }
  />
);

export default PrivateRoute;
