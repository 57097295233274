import 'antd/dist/antd.css';

import React from 'react';
import { Typography } from 'antd';

import Main from '../components/Main';

const { Title, Paragraph } = Typography;

const Home = () => (
  <Main>
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
      <Typography>
        <Title>Welcome to the MedTech Europe transparency platform</Title>
        <Paragraph>Use the search form located above to get started.</Paragraph>
      </Typography>
    </div>
  </Main>
);

export default Home;